<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container fluid>
      <v-card flat>
        <v-card-title>
          <v-row justify-end>
            <v-col v-if="hasWriteAccess">
              <v-icon left v-if="editing">mdi-pencil</v-icon>
              <v-icon left v-else> mdi-account</v-icon>
              {{ editing ? 'Update' : 'Create' }} Party
            </v-col>
            <v-col v-else>
              Party Details
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <span v-if="hasWriteAccess" class="req-diclaimer float-right">* = Required</span>
            </v-col>
          </v-row>
        </v-card-title>
        </v-card>
    <v-row>
      <v-col cols="6" sm="6" md="4">
        <v-select
          label="Party Type *"
          :items="party_types"
          item-text='name'
          item-value='id'
          v-model='partyData.party_type.id'
          color='primary'
          dense
          outlined
          required
          :rules="[(v) => !!v || 'Please select a party type']"
          :disabled="editing">
        </v-select>
       </v-col>
      <v-col cols="6" sm="6" md="8">
        <v-text-field
          v-model="partyData.name"
          :counter="name_max_length_rules"
          :rules="partynameRule"
          :readonly="!hasWriteAccess"
          label="Party Name *"
          color='primary'
          dense
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="6" md="4">
        <v-text-field
          v-model="partyData.email"
          :counter="name_max_length_rules"
          :rules="partyEmailRules"
          :label="isEmailRequired ? 'Party Email *' : 'Party Email'"
          :readonly="!hasWriteAccess"
          color='primary'
          dense
          outlined
          :required="isEmailRequired">
          <template v-slot:append-outer>
            <v-tooltip top v-if="!userExists">
              <template v-slot:activator="{ on }">
                <v-icon v-on='on' dense color='red'>mdi-alert-decagram-outline</v-icon>
              </template>
              <span>User Auth0 Account Not Found</span>
            </v-tooltip>
          </template>
        </v-text-field>
        <div v-if="editing && hasWriteAccess">
          <v-btn
            v-if="pwResetLogic"
            :disabled="blocked_auth0_access || !userExists"
            color="warning"
            class="mr-4"
            @click="sendPasswordReset">
            Password Reset
          </v-btn>
        </div>  
      </v-col>
      <v-col
        v-if="hasWriteAccess && partyData.id && !isUserParty" 
        style="max-width: 315px;" cols="6" sm="6" md="4">
        <v-menu
          ref="menu"
          :nudge-top="25"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="pickDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            dense
            :value="pickDate"
            label="Disable Date"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="pickDate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="endParty"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid>
    <v-row align="end">
      <v-col class="updated">
        Created By: {{partyData.created_by}}
        <br>
        Updated By: {{partyData.updated_by}}
        <br>
        Last Update: {{lastUpdateTime}}
      </v-col>
      <v-spacer></v-spacer>
      <v-col v-if="hasWriteAccess" class="d-flex justify-end">
        <div v-if="editing && partyData.party_type.constant == 'USER'">
          <v-btn
            :disabled="(blocked_auth0_access === true && !this.isAdmin) || !userExists"
            class="mr-4 red white--text"
            v-model="blocked_auth0_access"
            @click="changeAuth0UserAccess"
          >{{ blocked_auth0_access && this.isAdmin && userExists ? "Unblock User" : "Block User" }}</v-btn>
        </div>
        <v-btn
          v-if="editing"
          color="success"
          class="mr-4"
          @click="putParty">
          Update
        </v-btn>
        <v-btn
          v-else
          color="success"
          class="mr-4"
          @click="postParty">
          Create
        </v-btn>
        <v-btn
          color="warning"
          class="mr-4"
          @click="reset">
          Reset
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </v-container>
  </v-form>
</template>
<script>
import { cloneDeep } from 'lodash'
import Party from '../../axios/party-endpoint'

// mixins
import { displayAlert } from '@/mixins/alert'
import { formValidation } from '@/mixins/formvalidation'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'

export default {
  name: 'parties_edit',
  data () {
    return {
      crud: 'update',
      blocked_auth0_access: true,
      userExists: true,
      // isDisabled: false,
      info: null,
      menu: false,
      valid: true,
      endMenu: false,
      pickDate: null,
      partynameRule: [
        (v) => !!v || 'Party Name is required',
        (v) => { return this.validateLength(this.name_max_length_rules, v) },
        (v) => (v && v.length >= 3) || 'Minimum Party Name length is 3 characters'
      ],
      partytypeinfoRule: [
        (v) => !!v || 'Party Info is required'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      partyData: {
        name: '',
        email: '',
        party_type: {
          constant: '',
          name: '',
          id: ''
        },
        type_info: { info: '' },
        created_by: '',
        updated_by: '',
        updated_on: ''
      },
      id: '',
      excluded_party_types: {
          awg: ['OFFICE', 'PERISHABLE_GROUP', 'STORE_GROUP', 'TPR_GROUP', 'WAREHOUSE', 'WHOLESALER', 'DISTRIBUTION_CENTER']
        }
    }
  },
  computed: {
    showAuth0Controls(){
      return ['awg','jbg'].includes(this.tenant)
    },
    pwResetLogic() {
      return (this.showAuth0Controls && this.partyData.email != null && ['USER', 'OWNER', 'STORE'].includes(this.partyData.party_type?.constant))
    },
    isUserParty () {
      return this.party?.party_type?.constant === 'USER'
    },
    date(){
        return new Date().toISOString().substring(0, 10)
    },
    party_types () {
      let types = this.$store.getters.party_types
        if (this.tenant === 'awg' && !this.isAdmin) {
          types = types.filter(type => !this.excluded_party_types[this.tenant].includes(type?.constant))
        }
        return this.sortByKey(types,'name')
    },
    editing () {
      return Boolean(this.$route.query?.id)
    },
    lastUpdateTime () {
      if (this.partyData.updated_on) {
        return this.moment(this.partyData.updated_on).format(this.$config.timestamp)
      } else {
        return ''
      }
    },
    isEmailRequired () {
      const { id } = this.partyData.party_type
      if (id && this.party_types.length > 0) {
        // if creating a new party, name won't be populated
        const selected = this.party_types.find(type => type.id === id)
        return (selected.name.toLowerCase() === 'user')
      }
      return false
    },
    partyEmailRules () {
      const emailRulesDefault = [
        (v) => v && v.length > 0 ? (/.+@.+\..+/.test(v) || 'E-mail must be valid') : true
      ]
      return this.isEmailRequired
        ? [(v) => !!v || 'E-mail is required', ...emailRulesDefault]
        : emailRulesDefault
    }
  },
  props: {
    value: String,
    party: Object,
  },
  mixins: [displayAlert, formValidation, userAccess, utils],
  watch: {
    party: {
      handler(newValue) {
        if (this.isUserParty && this.showAuth0Controls) {
          this.fetchUserAuth0Status()
        }
        if (newValue?.id) {
          this.partyData = cloneDeep(newValue)
        }
      },
      deep: true
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  created() {
    if (this.party) {
      this.partyData = cloneDeep(this.party)
    }
  },
  methods: {
    async postParty () {
      this.valid = false
      this.validate()

      const { name, email, party_type } = this.partyData
      const payload = {
        name,
        email,
        type_id: party_type?.id,
        type_info: { info: '' },
        created_by: this.$store.getters.email
      }

      if (this.valid) {
        await Party.post(payload).then((response) => {
          this.emitAlert(true, 'success', `${name} has been successfully created.`)
          return this.$router.push({
            name: 'Parties Edit',
            query: { id: response.data.id }
          }).catch(err => console.error(err))
        })
          .catch((err) => {
            this.handleError(err)
          })
      } else {
        this.emitAlert(true, 'warning', 'Please check your form for errors')
      }
    },
    async sendPasswordReset() {
      const {email} = this.partyData
      const payload = {
        email: email,
      }
      try {
        const response = await Party.passwordReset(payload); 
        if (response.status === 200) {
            this.emitAlert(true, 'success','Password Reset has been successfully Sent.')
          } else {
            this.emitAlert(true, 'Warning',' Password Reset was not able to Send, Please Check Email.')
          }
      } catch (error) {
        this.handleError(error)
      }
    },
    async fetchUserAuth0Status() {
      const email = this.party.email
      const payload = {
        email: email
      }
      try {
        const response = await Party.getUserStatus(payload);
        this.blocked_auth0_access = response.data.is_blocked
      } catch (error) {
        if (error?.response?.data?.detail === 'User not found') {
          return this.userExists = false
        }
        this.handleError(error);
      }
    },
    async changeAuth0UserAccess() { 
      const email = this.partyData.email
      const payload = {
        id: this.party.id,
        email: email,
        is_blocked: !this.blocked_auth0_access
      }
      try {
        const res = await Party.auth0AccessChange(payload)
        if (res.status == 200) {
          if (res.config.params.is_blocked == true) {
            this.blocked_auth0_access = true
            await this.endParty();
          } else {
            this.blocked_auth0_access = false
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async putParty () {
      this.valid = false
      this.validate()
      const { id, name, email, party_type } = this.partyData
      const payload = {
        name,
        email: email || '',
        type_id: party_type.id,
        type_info: { info: '' },
        updated_by: this.$store.getters.email
      }
      if (this.valid) {
        await Party.put(payload, id).then(() => {
          this.emitAlert(true, 'success', name + ' has been successfully updated.')
        })
          .catch((err) => {
            this.handleError(err)
          })
      } else {
        this.emitAlert(true, 'warning', 'Please check your form for errors')
      }
    },
    async endParty () {
      const date = this.pickDate || this.date
      const { id } = this.partyData
      const payload = {
        id,
        end_date: date,
        username: this.$store.getters.email,
        tenant:"awg"
      }
      try{
        await Party.end(payload, id, date)
            this.emitAlert(true, 'success', 'Successfully ended.')
          }catch(err){
              this.handleError(err)
          } finally {
        this.endMenu = false
      }
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },
    back () {
      this.$router.push({ name: 'Parties' })
    },
    validateLength (maxLength, value) {
      if (parseInt(maxLength) > 0) {
        return (value.length <= parseInt(maxLength)) ? true : 'Party Name must be less than must be no longer than ' + maxLength + ' characters.'
      } else {
        return true
      }
    }
  }
}
</script>
<style scoped lang="scss">
.updated {
  font-size: small;
  font-weight: 400;
  font-style: italic;
}
.req-diclaimer {
  font-size: .8rem;
  font-weight: 400;
}
</style>
