export const utils = {
  methods: {
    async getAllSettled (promises, returnErrors = false) {
      const rejected = []
      const results = await Promise.allSettled(promises)
      const fulfilled = results.flatMap(result => {
        if (result.status === 'rejected') {
          rejected.push(result)
          return []
        }
        return result.value
      })
      if (rejected.length > 0) console.log('Rejected promises:', rejected)
      if (returnErrors) {
        return { fulfilled, rejected }
      }
      return fulfilled
    },
    sortByKey (arr, key) {
      arr.sort((a, b) => {
        if (a && b) {
          if (a[key] < b[key]) return -1
          else if (a[key] > b[key]) return 1
          else return 0
        }
      })
      return arr
    }
  }
}