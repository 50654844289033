import { get } from 'lodash'
export const formValidation = {
  data () {
    return {
      emailRegEx: /.*@{1}.*\..*/,
      valid: true
    }
  },
  computed: {
    name_max_length_rules () {
      return (this.partyData?.party_type?.constant === 'OWNER') ? 50 : ''
    }
  },
  methods: {
    disabled (saveData, requireds) {
      const missingValues = []
      requireds.forEach(field => {
        if (get(saveData, field) === '') {
          missingValues.push(field)
        }
      })

      return missingValues.length >= 1
    },
    inputType (field) {
      if (field.data_type.constant === 'INTEGER') {
        return 'number'
      } else if (field.data_type.constant === 'STRING') {
        const inputTypes = ['phone', 'email', 'password', 'tel']
        for (let i = 0; inputTypes.length > i; i++) {
          if (field.name.toLowerCase().indexOf(inputTypes[i]) >= 0) {
            return (inputTypes[i] === 'phone') ? 'tel' : inputTypes[i]
          }
        }

        return 'text'
      }
    },
    required (field, value) {
      return value !== '' || field + ' is required'
    },
    validate () {
      this.valid = this.$refs.form.validate()
    },
    validateDates (value) {
      if (value[this.validFields.before]) {
        if (value[this.validFields.before] < value[this.validFields.after]) {
          return true
        } else {
          return 'You must end old ' + this.value.attr.name + ' before you can start a new one.'
        }
      } else { return true }
    },

    /***
     * Attributes Only - this is function acts like the required function
     * but adds a second a check for attributes where there is an is_required
     * field.  At this moment functions both are needed.
     * @param {boolean}: requirement
     * @param {string}: name
     * @param {string}: value
     */
    validateEmpty (requirement, name, value) {
      if (value !== '') {
        if (requirement) {
          return !!value || name + ' is required'
        } else {
          return true
        }
      }
    },
    validateField (attrType, name, value) {
      if ((this.inputType(attrType) === 'email' && value !== '') || (this.inputType(attrType) === 'email' && attrType.is_required)) {
        const pattern = attrType.validation_string !== '' ? new RegExp(attrType.validation_string) : new RegExp(this.emailRegEx)
        return pattern.test(value) || 'Invalid e-mail.'
      } else {
        return true
      }
    },
    validateLength (maxLength, value) {
      if (parseInt(maxLength) > 0) {
        return (value.value.length <= parseInt(maxLength)) ? true : value.attr.name + ' must be no longer than ' + maxLength
      } else {
        return true
      }
    }
  }
}
