<template>
  <v-card flat color="transparent">
    <v-container fluid>
      <v-row no-gutters align-content="center" justify-content="start" style="margin-top: 10px; margin-bottom: 4px">
        <v-col cols="auto" align-self="center">
          <router-link to="/parties">
            <v-btn text class="primary--text">
              Parties
            </v-btn>
          </router-link>
        </v-col>
        <v-col cols="auto" align-self="center">
          <v-icon icon-right color="primary">mdi-chevron-right</v-icon>
        </v-col>
        <v-col cols="auto" align-self="center">
          <v-card-title style="padding-top: 0; padding-bottom: 0; padding-left: 15px">
            <template v-if="editing">
              {{ party ? `Edit ${ partyType === "STORE" ? '#' : '' }${partyAttributeValue} ${party.name}` : '' }}
            </template>
            <template v-else>
              Create Party
            </template>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="auto"
          class="col-auto ml-auto"
          align-self="center">
          <p v-if="party" class="text-body-1 pa-0;"
            style="margin: auto 10px 0 auto;">
            Party Type: {{ party.party_type.name }}
          </p>
        </v-col>
        <v-col
          v-if="partyAttributeValue"
          cols="auto"
          class="col-auto ml-auto"
          align-self="center">
          <p class="text-body-1 pa-0;"
            style="margin: auto 10px 0 20px;">
            {{ partyTypeLabel[partyType] }}: {{ partyType === "STORE" ? '#' : '' }}{{ partyAttributeValue }}
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-card>
      <v-tabs
        v-show="editing"
        @change="onTabChange"
        v-model="tab"
        grow
        background-color="transparent"
        color=primary>
        <v-tab v-for="item in items" :key="item.tab" @change="removeAlert()">
        <v-icon left>{{ item.icon }}</v-icon>
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-divider/>
      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <component
            :ref="`tab-${item.content}`"
            :is="item.content"
            v-model="partyId"
            :party="party"
            :partyAttribute="partyAttribute"
            :attributeid="attribute_type_id"
            @emittedPartyTypeId="partyTypeId = $event"
            :propsPartyTypeId="partyTypeId"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-card>
</template>
<script>
import Party from '@/axios/party-endpoint'
import PartyAttribute from '@/axios/party-attribute-endpoint'
// mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
// components
import PartyComponent from '@/components/parties/party'
const RelationshipComponent = () => import('@/components/parties/relationship')
const PhoneComponent = () => import('@/components/parties/phone')
const AddressComponent = () => import('@/components/parties/address')
const AttributeComponentNew = () => import('@/components/parties/attributes.vue')
export default {
  name: 'partyBase',
  data () {
    return {
      tab: 0,
      currentTab: 0,
      partyId: '',
      partyAttributeValue: '',
      partyAttribute: {
        begin_date: null
      },
      partyAttributeId: {
        VENDOR: 'VENDOR_ID',
        STORE: 'WHOLESALER_STORE_NUMBER'
      },
      partyType: '',
      partyTypeLabel: {
        VENDOR: 'Vendor ID',
        STORE: 'Store Number'
      },
      party: null,
      attribute_type_id: '',
      partyTypeId: '',
      items: [
        { tab: 'Party', content: 'PartyComponent', icon: 'mdi-account' },
        { tab: 'Attributes', content: 'AttributeComponentNew', icon: 'mdi-animation' },
        { tab: 'Relationship', content: 'RelationshipComponent', icon: 'mdi-arrow-left-right' },
        { tab: 'Phone', content: 'PhoneComponent', icon: 'mdi-phone-log' },
        { tab: 'Address', content: 'AddressComponent', icon: 'mdi-map-marker-radius' }
      ]
    }
  },
  watch: {
    $route: {
      handler (newValue) {
        if (newValue?.query?.id) {
          this.partyId = newValue.query.id
          this.getParty()
        }
      },
      deep: true
    }
  },
  beforeRouteLeave (to, from, next) {
    const result = this.checkAttrsTab()
    if (!result) {
      this.setNextFn(next)
      return false
    }
    return next()
  },
  mixins: [displayAlert, userAccess],
  beforeCreate () {
    this.$store.dispatch('getKeyValues')
  },
  created () {
    if (this.$route.query?.id) {
      this.partyId = this.$route.query.id
      this.getParty()
    }
  },
  computed: {
    attrsTab () {
      return this.items.findIndex(tab => tab.content === 'AttributeComponentNew')
    },
    editing () {
      return Boolean(this.$route.query?.id)
    }
  },
  methods: {
    async getParty () {
      const id = this.$route.query.id
      await Party.get(id)
        .then(response => {
          this.party = response.data
          this.partyType = this.party.party_type?.constant
          this.partyTypeId = this.party.party_type?.id
          if (response.data.type_info?.info) {
            const infoObj = {
              info: response.data.type_info
            }
            this.party.type_info = infoObj
          }
          this.attribute_type_id = this.party.party_type.id
          this.getPartyAttributeValue()
        })
        .catch((err) => {
          this.handleError(err)
        })
    },
    async getPartyAttributeValue () {
      if (["VENDOR", "STORE"].includes(this.partyType)) {
        PartyAttribute.attributeValue.getList(this.partyId, 100, 0)
        .then(response => {
          if (response.data.length > 0) {
            const wholesalerStoreNumber = response.data.find(e => e.attr.id === 'WHOLESALER_STORE_NUMBER')
            const beginDate = response.data.find(e => e.attr.id === 'BEGIN_DATE')
            this.partyAttributeValue = wholesalerStoreNumber ? wholesalerStoreNumber.value : ''
            this.partyAttribute.begin_date = beginDate ? beginDate.value : null
            }
          })
        .catch((err) => {
          this.handleError(err)
        })
      }
    },
    onTabChange (newVal) {
      if (this.currentTab === this.attrsTab && newVal !== this.attrsTab) {
        const result = this.checkAttrsTab()
        if (!result) {
          const nextFn = () => {
            this.currentTab = newVal
            return this.$nextTick(() => {
              this.tab = this.currentTab
            })
          }
          this.setNextFn(nextFn)
          this.$nextTick(() => {
            this.tab = this.currentTab
          })
          return
        }
      }
      this.currentTab = this.tab
    },
    checkAttrsTab () {
      const attrsRef = this.$refs['tab-AttributeComponentNew']
      if (attrsRef) {
        const missingAttrs = attrsRef[0].checkRequiredAttributes()
        if (missingAttrs) {
          this.emitAlert(true, 'warning', `Missing required attribute fields: ${[...missingAttrs].join(', ')}. Continue?`, [], true)
          return false
        }
      }
      return true
    },
    removeAlert () {
      this.emitAlert(false)
    }
  },
  components: {
    PartyComponent,
    AttributeComponentNew,
    RelationshipComponent,
    PhoneComponent,
    AddressComponent
  }
}
</script>
<style>
  a {
    text-decoration: none;
  }

  tr.v-data-table__selected {
      /* color: primary; */
      /* color: #81D4FA; */
      font-weight: bold;
    }
</style>
